import { useCustomMediaQuery } from './useCustomMediaQuery';

export default function useDeviceSize() {
  const isDesktop = useCustomMediaQuery('(min-width: 1024px)');
  const isTablet = useCustomMediaQuery(
    '(min-width:768px) and (max-width:1023px)'
  );
  const isMobile = useCustomMediaQuery('(max-width:767px)');

  return { isDesktop, isTablet, isMobile };
}
