export const getCountryFromLanguage = (languageCode) => {
  // 국가 코드 매핑
  const countryMapping = {
    KR: "South Korea",
    US: "United States",
    JP: "Japan",
    CN: "China",
    // 추가 국가 코드를 여기에 추가할 수 있습니다.
  };

  // 언어 코드에서 국가 코드 추출
  const countryCode = languageCode.split("-")[1];

  // 국가 코드로 국가 이름 찾기
  return countryMapping[countryCode] || "Unknown Country";
};

export const getDeviceInfo = (userAgent) => {
  // 사용자 에이전트 분석
  const ua = userAgent.toLowerCase();

  if (ua.includes("mobile")) {
    return "Mobile Device";
  }

  if (ua.includes("tablet")) {
    return "Tablet";
  }

  if (ua.includes("windows")) {
    return "Windows PC";
  }

  if (ua.includes("macintosh") || ua.includes("mac os")) {
    return "Mac";
  }

  if (ua.includes("linux")) {
    return "Linux PC";
  }

  return "Unknown Device";
};
