import React, { useEffect, useState, useMemo } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../common/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetail } from "../../apps/user";
import moment from "moment";

export default function ViewPost() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const { userDetail } = useSelector((state) => state.user);

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        const resp = await dispatch(fetchUserDetail({
            userId: id
        }));
    };

    return (
        <div>
            <Sidebar />
            <div className="card">
                <div className="card-header bg-white card-header-wrapper py-3">
                    <h3 className="mb-0">User Detail</h3>
                </div>
                <div className="table-responsive user_detail_card">
                    <Table className="mb-0">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{userDetail?.firstName} {userDetail?.lastName ?? ''}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{userDetail?.email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{userDetail?.phone}</td>
                            </tr>
                            <tr>
                                <th>DOB</th>
                                <td>{userDetail?.dob}</td>
                            </tr>
                            <tr>
                                <th>Gender</th>
                                <td>{userDetail?.gender}</td>
                            </tr>
                            <tr>
                                <th>Country</th>
                                <td>{userDetail?.country}</td>
                            </tr>
                            <tr>
                                <th>Education</th>
                                <td>{userDetail?.education}</td>
                            </tr>
                            <tr>
                                <th>Employment</th>
                                <td>{userDetail?.employment}</td>
                            </tr>
                            <tr>
                                <th>Created At</th>
                                <td>{moment(userDetail?.createdAt).format("MMMM DD, YYYY HH:mm A")}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
}
