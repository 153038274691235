import React from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { useLocation } from "react-router-dom";
const Layout = (props) => {
  const location = useLocation();
  return (
    <>
      <div className="wrapper">
        <div
          className={`${location.pathname === "/login" || location.pathname === "/" ? "main p-0" : "main"}`}
        >
          {location.pathname === "/login" || location.pathname === "/" ? null : <Header />}
          {props.children}
        </div>
      </div>
    </>
  );
};
export default Layout;
