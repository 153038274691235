import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import './StripeSection.css';

const StripeSection = () => {
  return (
    <section>
      <Container>
        <div className="stripe_title_container">
          <div className="section_heading">
            One more thing. <br />
            $1 secures your $299 deal-guaranteed!
            <br />
          </div>
          <div className={'section_sub'}>
            pay just $1 to secure the lowest price $299. Regardless of your
            position in line, we guaranteed that you'll be able to purchase it
            for $299.
          </div>
        </div>

        <div className="stripe_button_container">
          <motion.button
            id={'gtm_stripe_button'}
            onClick={() => {
              window.open(
                'https://buy.stripe.com/7sI5nU35Fdm0cHC4gg',
                '_blank'
              );
            }}
            whileHover={{ scale: 1.2 }} // Scale up to 120% on hover
            transition={{ type: 'spring', stiffness: 300 }} // Smooth transition
            className="stripe_button"
          >
            Lock the deal
          </motion.button>
        </div>
      </Container>
    </section>
  );
};

export default StripeSection;
