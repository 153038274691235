import React, { useState, useEffect, useRef } from 'react';
import './PriceAnimation.css';

const PriceAnimation = ({
  endValue = 299,
  duration = 1000,
  discountValue = 43,
  hasDiscount = false,
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const stepTime = 10; // 숫자 업데이트 간격 (밀리초)
    const steps = duration / stepTime;
    const increment = endValue / steps;
    let value = 0;
    let animationFrameId;

    const updatePrice = () => {
      value += increment;
      if (value >= endValue) {
        setCurrentValue(endValue);
        setIsAnimationComplete(true); // 애니메이션 완료
      } else {
        setCurrentValue(Math.floor(value));
        animationFrameId = requestAnimationFrame(updatePrice);
      }
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          updatePrice();
        } else {
          // 애니메이션 중지
          setCurrentValue(0);
          setIsAnimationComplete(false); // 애니메이션 초기화
          if (animationFrameId) {
            cancelAnimationFrame(animationFrameId);
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 1, // 요소의 100%가 보일 때 트리거
    });

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [endValue, duration]);

  return (
    <>
      <span style={{ color: 'white' }}>$</span>
      <span ref={ref} className="">
        {`$${currentValue}`}
      </span>
      {hasDiscount && (
        <span
          className={`discount-badge ${
            isAnimationComplete ? 'fade-in' : 'fade-out'
          }`}
        >
          {`${discountValue}% OFF`}
        </span>
      )}
    </>
  );
};

export default PriceAnimation;
