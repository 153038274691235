import * as Yup from 'yup';
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const UserAddSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    // .matche(phoneRegExp,'Phone number is not valid')

    .required('Required'),
  country: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  password: Yup.string()
    .min(4, 'Too Short!')
    .max(10, 'Too Long!')
    .required('Required'),

  email: Yup.string().email('Invalid email').required('Required'),
});
export const UserEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    // .matche(phoneRegExp,'Phone number is not valid')

    .required('Required'),
  country: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),

  email: Yup.string().email('Invalid email').required('Required'),
});

export const CategorySchema = Yup.object().shape({
  category_name: Yup.string().required('Required'),
  image: Yup.string().required('Required'),
});
export const VendorSchema = Yup.object().shape({
  vendor_name: Yup.string().required('Required'),
  image: Yup.string().required('Required'),
});
export const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});
export const ProductSchema = Yup.object().shape({
  product_name: Yup.string().required('Product name is required'),
  // images: Yup.array()
  //   .min(1, "At least one image is required")
  //   .required("Images are required"),
  colors: Yup.array()
    .min(1, 'At least one color is required')
    .required('Colors are required'),
  tags: Yup.array()
    .min(1, 'At least one tag is required')
    .required('Tags are required'),
  price: Yup.number()
    .typeError('Price must be a number')
    .required('Price is required'),
  category: Yup.string().required('Category is required'),
  // _id: Yup.string().required("_id is required"),
});

export const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export const resetPasswordSchema = Yup.object({
  currentPassword: Yup.string().required('Current password is required.'),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/,
      'Password must meet the criteria of 8-16 alphanumeric character with min 1 special character.'
    )
    .required('Password is required.'),
  passwordConfirm: Yup.string()
    .required('Confirm password is required.')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.'),
});

// export const NotifySchema = Yup.object().shape({
//   email: Yup.string().email('Invalid email').required('Required'),
//   phone: Yup.string().required('Required'),
//   // name: Yup.string().required("Required"),
// });
const atLeastOneRequired = (value, context) => {
  const { email, phone } = context.parent;
  if (!email && !phone) {
    return context.createError({
      message: 'Either email or phone is required',
    });
  }
  return true;
};

export const NotifySchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .test(
      'at-least-one',
      'Either email or phone is required',
      atLeastOneRequired
    ),

  phone: Yup.string().test(
    'at-least-one',
    'Either email or phone is required',
    atLeastOneRequired
  ),
});

const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validateEmail = (email) => {
  return emailRegExp.test(email);
};
