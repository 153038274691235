import { useEffect, useState, useRef } from 'react';
import historyimg from '../../assets/images/history.png';
import './History.css';

const History = () => {
  const [inView, setInView] = useState({
    zero: false,
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
  });

  const refZero = useRef(null);
  const refOne = useRef(null);
  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);
  const refFive = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInView((prev) => ({
            ...prev,
            [entry.target.dataset.index]: true,
          }));
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    if (refZero.current) observer.observe(refZero.current);
    if (refOne.current) observer.observe(refOne.current);
    if (refTwo.current) observer.observe(refTwo.current);
    if (refThree.current) observer.observe(refThree.current);
    if (refFour.current) observer.observe(refFour.current);
    if (refFive.current) observer.observe(refFive.current);

    return () => {
      if (refZero.current) observer.unobserve(refZero.current);
      if (refOne.current) observer.unobserve(refOne.current);
      if (refTwo.current) observer.unobserve(refTwo.current);
      if (refThree.current) observer.unobserve(refThree.current);
      if (refFour.current) observer.unobserve(refFour.current);
      if (refFive.current) observer.unobserve(refFive.current);
    };
  }, []);

  return (
    <div className="container-default">
      <div className={'card-container grid-3-columns'}>
        <div
          className={'card card-page card-content'}
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            className={'img-section'}
            style={{
              backgroundColor: 'rgb(245, 245, 245)',
              objectFit: 'cover',
            }}
          >
            <img
              src={historyimg}
              sizes="(max-width: 479px) 40vw, (max-width: 767px) 40vw, (max-width: 991px) 40vw, (max-width: 1919px) 40vw"
              alt="History"
            />
          </div>
          <div
            className={'card-text-wrapper text-section'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f5f5f5',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            <div
              className={'text-section-title'}
              data-index="zero"
              ref={refZero}
              style={{
                opacity: inView.zero ? 1 : 0,
                transform: inView.zero ? 'translateY(0)' : 'translateY(20px)',
                transition: 'opacity 0.5s, transform 0.5s',
              }}
            >
              DIVEROID KICKSTARTER IS BACK!
            </div>
            <div
              data-index="one"
              className={'text-section-content'}
              ref={refOne}
              style={{
                opacity: inView.one ? 1 : 0,
                transform: inView.one ? 'translateY(0)' : 'translateY(20px)',
                transition: 'opacity 0.5s, transform 0.5s',
              }}
            >
              Promises made
            </div>
            <div
              data-index="two"
              className={'text-section-content'}
              ref={refTwo}
              style={{
                opacity: inView.two ? 1 : 0,
                transform: inView.two ? 'translateY(0)' : 'translateY(20px)',
                transition: 'opacity 0.5s 0.5s, transform 0.5s 0.5s',
              }}
            >
              Promises kept
            </div>
            <div
              data-index="three"
              className={'text-section-content'}
              ref={refThree}
              style={{
                opacity: inView.three ? 1 : 0,
                transform: inView.three ? 'translateY(0)' : 'translateY(20px)',
                transition: 'opacity 0.5s 1s, transform 0.5s 1s',
              }}
            >
              Products delivered
            </div>
            <div
              data-index="four"
              className={'text-section-content'}
              ref={refFour}
              style={{
                opacity: inView.four ? 1 : 0,
                transform: inView.four ? 'translateY(0)' : 'translateY(20px)',
                transition: 'opacity 0.5s 1.5s, transform 0.5s 1.5s',
              }}
            >
              Join the dive revolution
            </div>
            <div
              data-index="five"
              ref={refFive}
              className="wavy-blue-gradient-text"
              style={{
                opacity: inView.five ? 1 : 0,
                transform: inView.five ? 'translateY(0)' : 'translateY(20px)',
                transition: 'opacity 1s 2s, transform 1s 3s',
              }}
            >
              The 7th Project of Diveroid
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
