import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

export const ThirdPartyInit = () => {
  useEffect(() => {
    console.log('GA init');
    // ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);
  }, []);

  useEffect(() => {
    console.log('GTM init');
    TagManager.initialize({
      gtmId: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER}`,
    });
  }, []);

  useEffect(() => {
    console.log('hotjar tracking initialized');
    // Insert Hotjar tracking code into the head section
    const script = document.createElement('script');
    script.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:5094563,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
    document.head.appendChild(script);

    return () => {
      // Cleanup if necessary
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    console.log('Meta Pixel tracking initialized');
    // Insert Meta Pixel tracking code into the head section
    const metaPixelScript = document.createElement('script');
    metaPixelScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '440067515658695');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(metaPixelScript);

    // Insert Meta Pixel noscript tag
    const metaPixelNoscript = document.createElement('noscript');
    metaPixelNoscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=440067515658695&ev=PageView&noscript=1"
      />
    `;
    document.body.appendChild(metaPixelNoscript);

    return () => {
      // Cleanup if necessary
      document.head.removeChild(metaPixelScript);
      document.body.removeChild(metaPixelNoscript);
    };
  }, []);

  return <></>;
};
