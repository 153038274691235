import { useEffect, useRef } from 'react';
import './TextFadeAnimation.css';

const TextFadeAnimation = ({ text, duration, color }) => {
  const textRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
          } else {
            entry.target.style.opacity = 0;
          }
        });
      },
      { threshold: 1 } // Trigger when 10% of the element is visible
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={textRef}
      className="text-overlay text-container"
      style={{ color: color }}
    >
      {text}
    </div>
  );
};

export default TextFadeAnimation;
