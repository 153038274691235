import { ThirdPartyInit } from './analytics/ThirdPartyInit';
import './App.css';
import './assets/fonts/FontStyle.css';
import Layout from './Layout/Layout';
import Routing from './Layout/Routing';

function App() {
  return (
    <>
      <ThirdPartyInit />
      <Layout>
        <Routing />
      </Layout>
    </>
  );
}

export default App;
