import flags from 'emoji-flags';

const COUNTRY_CODE = [
  { value: '1', label: 'United States +1', code: 'US' },
  { value: '82', label: 'South Korea +82', code: 'KR' },
  { value: '44', label: 'United Kingdom +44', code: 'GB' },
  { value: '33', label: 'France +33', code: 'FR' },
  { value: '49', label: 'Germany +49', code: 'DE' },
  { value: '34', label: 'Spain +34', code: 'ES' },
  { value: '39', label: 'Italy +39', code: 'IT' },
  { value: '81', label: 'Japan +81', code: 'JP' },
  { value: '86', label: 'China +86', code: 'CN' },
  { value: '91', label: 'India +91', code: 'IN' },
  { value: '61', label: 'Australia +61', code: 'AU' },
  { value: '55', label: 'Brazil +55', code: 'BR' },
  { value: '54', label: 'Argentina +54', code: 'AR' },
  { value: '7', label: 'Russia +7', code: 'RU' },
  { value: '27', label: 'South Africa +27', code: 'ZA' },
  { value: '60', label: 'Malaysia +60', code: 'MY' },
  { value: '65', label: 'Singapore +65', code: 'SG' },
  { value: '52', label: 'Mexico +52', code: 'MX' },
  { value: '45', label: 'Denmark +45', code: 'DK' },
  { value: '47', label: 'Norway +47', code: 'NO' },
  { value: '48', label: 'Poland +48', code: 'PL' },
  { value: '84', label: 'Vietnam +84', code: 'VN' },
  { value: '58', label: 'Venezuela +58', code: 'VE' },
  { value: '972', label: 'Israel +972', code: 'IL' },
  { value: '98', label: 'Iran +98', code: 'IR' },
  { value: '90', label: 'Turkey +90', code: 'TR' },
  { value: '212', label: 'Morocco +212', code: 'MA' },
  { value: '213', label: 'Algeria +213', code: 'DZ' },
  { value: '254', label: 'Kenya +254', code: 'KE' },
  { value: '233', label: 'Ghana +233', code: 'GH' },
  { value: '229', label: 'Benin +229', code: 'BJ' },
  { value: '237', label: 'Cameroon +237', code: 'CM' },
  { value: '242', label: 'Congo +242', code: 'CG' },
  { value: '250', label: 'Rwanda +250', code: 'RW' },
  { value: '243', label: 'Democratic Republic of the Congo +243', code: 'CD' },
  { value: '298', label: 'Faroe Islands +298', code: 'FO' },
  { value: '672', label: 'Australian External Territories +672', code: 'AX' }, // Example for specific territories
  { value: '1868', label: 'Trinidad and Tobago +1868', code: 'TT' },
  { value: '1876', label: 'Jamaica +1876', code: 'JM' },
  { value: '1345', label: 'Cayman Islands +1345', code: 'KY' },
  { value: '1242', label: 'Bahamas +1242', code: 'BS' },
  { value: '1809', label: 'Dominican Republic +1809', code: 'DO' },
  { value: '1787', label: 'Puerto Rico +1787', code: 'PR' },
  { value: '1869', label: 'Saint Kitts and Nevis +1869', code: 'KN' },
  { value: '852', label: 'Hong Kong +852', code: 'HK' },
  { value: '64', label: 'New Zealand +64', code: 'NZ' },
  { value: '503', label: 'El Salvador +503', code: 'SV' },
  { value: '507', label: 'Panama +507', code: 'PA' },
  { value: '51', label: 'Peru +51', code: 'PE' },
  { value: '598', label: 'Uruguay +598', code: 'UY' },
  { value: '260', label: 'Zambia +260', code: 'ZM' },
  { value: '263', label: 'Zimbabwe +263', code: 'ZW' },
  { value: '965', label: 'Kuwait +965', code: 'KW' },
  { value: '973', label: 'Bahrain +973', code: 'BH' },
  { value: '971', label: 'United Arab Emirates +971', code: 'AE' },
  { value: '974', label: 'Qatar +974', code: 'QA' },
  { value: '966', label: 'Saudi Arabia +966', code: 'SA' },
];

const getFlagEmoji = (countryCode) => {
  const country = flags.countryCode(countryCode);
  return country ? country.emoji : '';
};

export const countryCodeOptions = COUNTRY_CODE.map((country) => ({
  value: country.value,
  label: `${getFlagEmoji(country.code)} ${country.label}`,
  code: country.code,
}));
