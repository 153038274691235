import React, { useRef, useState, useEffect } from 'react';
import { motion, useTransform, useScroll } from 'framer-motion';
import './Style.css';
import TextFadeAnimation from '../common/animation/TextFadeAnimation';

const ScrollImage = ({ text, BeforeImg, AfterImg, index }) => {
  const BOX_MARGIN = 150;
  const ref = useRef(null);
  const refImage = useRef(null);
  const { scrollY } = useScroll();
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('down');
  const [imageRevealed, setImageRevealed] = useState(false);
  const [belowViewport, setBelowViewport] = useState(false);

  useEffect(() => {
    return scrollY.onChange((value) => {
      if (value > prevScrollY) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      setPrevScrollY(value);
    });
  }, [scrollY, prevScrollY]);

  const y = useTransform(scrollY, (value) => {
    if (!refImage.current) return 0;

    const elementTop = refImage.current.getBoundingClientRect().top + value;
    const viewportHeight = window.innerHeight;
    const elementHeight = refImage.current.offsetHeight;
    const elementCenter = elementTop + elementHeight / 2;
    const viewportCenter = value + viewportHeight / 2;

    return Math.max(
      0,
      Math.min(
        1,
        1 -
          Math.abs(viewportCenter - BOX_MARGIN - elementCenter) /
            (viewportHeight / 2)
      )
    );
  });

  const clipPath = useTransform(
    y,
    [0, 1],
    scrollDirection === 'down'
      ? imageRevealed
        ? ['inset(0px)', 'inset(0px)']
        : ['inset(100% 0 0 0)', 'inset(0% 0 0 0)']
      : !belowViewport
      ? ['inset(0px)', 'inset(0px)']
      : ['inset(0px)', 'inset(100% 0 0 0)']
  );

  useEffect(() => {
    const checkImageReveal = (latestY) => {
      if (!ref.current) return;

      const rect = refImage.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // 화면 아래쪽에 위치하며 뷰포트에 보이지 않는 경우
      const isBelowViewport = rect.top > viewportHeight;
      const isRevealed = latestY > 0.94;

      // 화면 위쪽에 위치하는 경우 초기화하지 않음
      const isAboveViewport = rect.bottom < 0;
      setBelowViewport(isBelowViewport);

      if (scrollDirection === 'down' && !imageRevealed && isRevealed) {
        setImageRevealed(true);
      } else if (
        scrollDirection === 'up' &&
        imageRevealed &&
        !isRevealed &&
        isBelowViewport
      ) {
        setImageRevealed(false);
      }
    };

    checkImageReveal(y.get());

    const unsubscribe = scrollY.onChange(() => {
      checkImageReveal(y.get());
    });

    return () => unsubscribe();
  }, [y, scrollY, scrollDirection, imageRevealed]);

  return (
    <div
      className="scroll-section-container"
      style={
        index === 1
          ? {
              backgroundColor: 'black',
              color: 'white',
            }
          : {}
      }
    >
      <TextFadeAnimation text={text} color={index === 1 ? 'white' : 'black'} />
      <div className="scroll-image-container" ref={ref}>
        <div className="image-wrapper">
          <img
            ref={refImage}
            src={BeforeImg}
            alt="Before"
            className="before-img"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}
          />
          {AfterImg && (
            <motion.img
              src={AfterImg}
              alt="After"
              className="after-img"
              style={{
                clipPath: imageRevealed ? 'inset(0px) !important' : clipPath,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                transition: 'clip-path 0.5s ease-out',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScrollImage;
