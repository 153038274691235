import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { motion, useTransform, useScroll } from 'framer-motion';
import {
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap';
import Wave from 'react-wavify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import SwiperCore from 'swiper/core';
import Select from 'react-select';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { NotifySchema, validateEmail } from '../validations';
import { notify } from '../apps/auth';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autoplay,
  Breakpoints,
  EffectCreative,
  EffectCards,
} from 'swiper/modules';
import { Navigation } from 'swiper/modules';

//etc
import { getDeviceInfo } from '../utils/info';
import { analyticEvent, eventTrigger, pageviewTrigger } from '../utils/gtag';
import Swal from 'sweetalert2';
import ClipLoader from 'react-spinners/ClipLoader';
import { differenceInMilliseconds } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { countryCodeOptions } from '../utils/countryCode';
import { parsePhoneNumber } from 'react-phone-number-input';

import ScrollImage from './ScrollImage';
import AfterImg1 from '../assets/images/img-filter-after.jpg';
import BeforeImg1 from '../assets/images/img-filter-before.jpg';
import AfterImg2 from '../assets/images/img-product-diving-front.png';
import BeforeImg2 from '../assets/images/img-product-diving-back.png';
import useDeviceSize from '../hooks/useDeviceSize';
import PriceAnimation from '../common/animation/PriceAnimation';
import StripeSection from './Stripe/StripeSection';
import History from './History/History';
// Install the Mousewheel module
SwiperCore.use([Mousewheel]);
// Install the Autoplay module
SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation]);

const data = [
  {
    id: 1,
    text: 'Diving Computer',
    AfterImg: AfterImg2,
    BeforeImg: BeforeImg2,
  },
  {
    id: 2,
    text: 'Real-Time Color Correction',
    AfterImg: AfterImg1,
    BeforeImg: BeforeImg1,
  },
  {
    id: 3,
    text: 'Compatibility with Various Devices',
    BeforeImg: '/images/compatibility.gif',
  },
];

export default function LandingPage() {
  const targetDate = toZonedTime('2024-09-03T12:00:00Z', 'America/New_York');
  // const targetDate = toZonedTime('2024-08-31T08:00:00Z', 'America/New_York');

  const [isLaunched, setIsLaunched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonVisible, setButtonVisible] = useState(true);
  const youtubeUrls = { A: '_pf9QM34hwI', B: 'Q_gB-5Uv7CM' };

  const location = useLocation();
  const dispatch = useDispatch();

  // const isMobile = useMemo(() => window.innerWidth <= 768, [window.innerWidth]);
  const { isMobile } = useDeviceSize();
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const fixedElementRef = useRef(null);

  const calculateTimeLeft = () => {
    const now = toZonedTime(new Date(), 'America/New_York'); // Current local time

    console.log('targetDate : ', targetDate);
    console.log('now : ', now);
    const distance = differenceInMilliseconds(targetDate, now);

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (days < 0 && hours < 0 && minutes < 0 && seconds < 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isExpired: true,
      };
    }
    return {
      days,
      hours,
      minutes,
      seconds,
      isExpired: distance < 0,
    };
  };

  const goKickstarter = () => {
    window.open(
      'https://www.kickstarter.com/projects/officialdiveroid/diveroid-turn-your-smartphone-into-an-all-in-one-dive-gear-3?ref=cphsy3&token=6b15ccdd',
      '_blank'
    );
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerId); // Cleanup interval on component unmount
  }, [targetDate]);

  // Launched time check
  useEffect(() => {
    const checkLaunchStatus = () => {
      // const currentDate = new Date();
      const currentDate = toZonedTime(new Date(), 'America/New_York');

      const targetDateTime = new Date(targetDate);

      if (currentDate >= targetDateTime) {
        setIsLaunched(true);
      }
    };

    checkLaunchStatus();

    // Optional: Set up an interval to check periodically
    const interval = setInterval(checkLaunchStatus, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  useEffect(() => {
    pageviewTrigger(location);
  }, []);

  const ALERT_MESSAGES = {
    both: {
      title: 'Send Email👋',
      html: `
            <div>We have sent an email for verification.✅</div>
            <div>Please click the <b style="color: blue;">Confirm</b> button in the email</div>
            <br/>
            <div id="swal2-html-notice" style="font-size:11px; color:gray"> ⚠️ Click the <b style="color: blue;">Confirm</b> button to complete the final submission.⚠️ </div>.
          `,
      icon: 'success',
    },
    phone: {
      title: '👋',
      html: `
            <div>Thank you for your subscription.✅</div>
            <div>Thank you for being with Diveroid.💙</div>
          `,
      icon: 'success',
    },
    email: {
      title: 'Send Email!👋',
      html: `
            <div>Thank you for your subscription.💙</div>
            <div>We have sent an email for verification.✅</div>
            <div>Please click the <b style="color: blue;">Confirm</b> button in the email</div>
            <br/>
            <div id="swal2-html-notice" style="font-size:11px; color:gray"> ⚠️ Click the <b style="color: blue;">Confirm</b> button to complete the final submission.⚠️ </div>.
          `,
      icon: 'warning',
    },
  };

  const showAlert = (msg) => {
    Swal.fire({
      title: msg.title,
      html: msg.html,
      icon: msg.icon,
      confirmButtonText: '<span style="color: white;">OK</span>',
      confirmButtonColor: '#007bff', // Customize the OK button color
      customClass: {
        popup: 'custom-popup',
        confirmButton: 'custom-confirm-button',
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      // name: ""
      countryCode:
        countryCodeOptions.find((option) =>
          navigator?.language
            ?.toLocaleLowerCase()
            ?.includes(option?.code?.toLocaleLowerCase())
        )?.value || '1',
      phone: '',
    },
    validationSchema: NotifySchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      // eventTrigger("click", "Form Submission", "Click Sumbit Button", 0);

      const validPhone = parsePhoneNumber(
        `+${values?.countryCode}${values?.phone}`,
        countryCodeOptions.find((option) => option.value === values.countryCode)
          .code
      );

      if (!validateEmail(values?.email) && !validPhone?.isValid()) {
        setIsLoading(false);
        toast.warning('Please enter a valid email or phone number');
        return;
      }

      if (
        validateEmail(values?.email) &&
        values.phone?.length > 0 &&
        !validPhone?.isValid()
      ) {
        setIsLoading(false);
        toast.warning('Please enter a valid email or phone number');
        return;
      }
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_API}/api/v1/users`,
          {
            product: 'Universal Pro & Mini',
            email: values.email,
            countryCode: countryCodeOptions.find(
              (item) => item.value === values.countryCode
            )?.code,
            phoneNumber: values.phone,
            device: getDeviceInfo(navigator.userAgent), // 브라우저 정보
            location: navigator.language, // 언어 설정
          }
        );
        if (res?.status === 201 || res?.status === 200) {
          if (validPhone?.isValid() && validateEmail(values.email)) {
            showAlert(ALERT_MESSAGES['both']);
          } else if (validPhone?.isValid()) {
            showAlert(ALERT_MESSAGES['phone']);
          } else {
            showAlert(ALERT_MESSAGES['email']);
          }

          analyticEvent('Submit', 'success');
          // eventTrigger({action: "click",category: "Submit Success", label: `Click Sumbit Button success`,  value: 1});
          resetForm();
        }
        setIsLoading(false);
      } catch (error) {
        console.log('error : ', error);

        if (error.response && error.response.status === 400) {
          toast.warning('We have sent an email ✉️. Please check your inbox.');
        } else {
          toast.error('Not subscribed');
        }
        analyticEvent('Submit', 'failure');
        setIsLoading(false);
      }
    },
  });

  const selectedOption = countryCodeOptions.find(
    (option) => option.value === formik.values.countryCode
  );

  const phoneValidate = useMemo(() => {
    return parsePhoneNumber(
      `+${formik.values.countryCode}${formik.values.phone}`,
      countryCodeOptions.find(
        (option) => option.value === formik.values.countryCode
      ).code
    );
  }, [formik.values.countryCode, formik.values.phone]);

  const [activeClass, setActiveClass] = useState('');

  // const handleScroll = () => {
  //     const fixedElement = fixedElementRef.current;
  //     if (!fixedElement) return;

  //     const fixedElementRect = fixedElement.getBoundingClientRect();

  //     const scrollPosition = window.scrollY + window.innerHeight / 2; // Center of viewport
  //     const sectionRefs = [section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref];
  //     const sectionClasses = ['herobanner', 'purchase-count', 'sliderSection', 'video-section', 'notify-section', 'footer'];

  //     const activeSectionIndex = sectionRefs.findIndex((ref, index) => {
  //         const element = ref.current;
  //         if (element) {
  //             const rect = element.getBoundingClientRect();
  //             const elementTop = rect.top + window.scrollY;
  //             const elementBottom = rect.bottom + window.scrollY;

  //             if (scrollPosition >= elementTop && scrollPosition <= elementBottom) {
  //                 // Fetch the HTML content and check the class name
  //                 const htmlContent = element.innerHTML;
  //                 const className = element.className;
  //                 return true;
  //             }
  //         }
  //         return false;
  //     });

  //     if (activeSectionIndex === -1) {
  //         setActiveClass('');
  //     } else {
  //         setActiveClass(
  //             (sectionClasses[activeSectionIndex] == 'herobanner'
  //                 || sectionClasses[activeSectionIndex] == 'notify-section'
  //                 || sectionClasses[activeSectionIndex] == 'footer')
  //                 ? 'd-none' : ''
  //             );
  //         //setActiveClass(sectionClasses[activeSectionIndex]);
  //     }
  // };
  // useEffect(() => {
  //     window.addEventListener('scroll', handleScroll);
  //     handleScroll(); // Check initial position

  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);

  // console.log(activeClass);

  const handleNotifyMe = () => {
    if (section5Ref.current) {
      section5Ref.current.focus();
      window.scrollTo({
        top: section5Ref.current.offsetTop,
        behavior: 'smooth',
      });
      // eventTrigger("click", "User Interaction", "Go Sumbit Form", 1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (section5Ref.current) {
        const textareaElement = section5Ref.current;
        const rect = textareaElement.getBoundingClientRect();
        const isInViewport = rect.top < window.innerHeight && rect.bottom > 0;
        setButtonVisible(!isInViewport);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`cta-section w-100 ${activeClass}`}
        ref={fixedElementRef}
        style={{
          opacity: isButtonVisible ? 1 : 0,
          transition: 'opacity 0.5s ease',
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <div className="cta-card ">
                <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap">
                  <div>
                    <h4 className="cta-heading text-white mb-0">
                      {isLaunched
                        ? 'Now launched on KICKSTARTER!!'
                        : 'Sign up Now for a 1-Hour Early Access Alert'}
                    </h4>
                  </div>
                  <div>
                    <button
                      id="gtm-button-floating-notifyme"
                      className="btn btn-white bg-white"
                      onClick={isLaunched ? goKickstarter : handleNotifyMe}
                    >
                      {isLaunched ? 'GO KICKSTARTER' : 'Notify Me'}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="herobanner position-relative" ref={section1Ref}>
        <div className="banner-content w-100 h-100">
          <Container>
            <h1 className="text-center text-white d-flex gap-lg-3 gap-2 flex-wrap align-items-center justify-content-center main-heading">
              First 5 backers:{' '}
              <span className="position-relative crossed-price text-center">
                $528
              </span>{' '}
              $299 <span className="offerSpan rounded-pill">43% OFF</span>{' '}
            </h1>
            {!isLaunched && (
              <p className="text-center text-white banner-date-text mb-4">
                September 3rd, 8:00 AM EST
              </p>
            )}
            <div
              className="d-flex align-items-center justify-content-center flex-wrap gap-2"
              style={{
                flexDirection: 'row',
              }}
            >
              <h3 className="mb-0 text-white launching-text">
                {isLaunched ? 'Now launched on' : 'Launching on'}
              </h3>
              <div>
                <img
                  id="kickstarter-logo"
                  src={require('../assets/images/kickster-logo.png')}
                  alt=""
                  className=""
                />
              </div>
            </div>
            {isLaunched && (
              <div className={'kickstarter-button-container'}>
                <div className={'kickstarter-button'} onClick={goKickstarter}>
                  GO KICKSTARTER
                </div>
              </div>
            )}
          </Container>
        </div>
        <div id="gtm-img-banner" className="">
          {/* <Swiper
                        direction="horizontal"
                        spaceBetween={0}
                        slidesPerView={1}
                        autoplay={{
                            delay: 3000, // Delay between slides in milliseconds
                            disableOnInteraction: false, // Continue autoplay after user interaction
                          }}
                        loop={false} // Enable loop mode
                        mousewheel={true} // Enable mousewheel control
                        pagination={{ clickable: false }}
                        navigation={{ clickable: false }}
                    >
                        <SwiperSlide>
                            <img src={require('../assets/images/herobanner-img1.png')} alt="" className='sliderImage' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={require('../assets/images/herobanner-img2.png')} alt="" className='sliderImage' />
                        </SwiperSlide>
                    </Swiper> */}
          <Swiper
            grabCursor={true}
            effect={'creative'}
            autoplay={{
              delay: 3000, // Delay between slides in milliseconds
              disableOnInteraction: false, // Continue autoplay after user interaction
            }}
            creativeEffect={{
              prev: {
                shadow: false,
                translate: [0, 0],
                opacity: 0.9,
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }}
            modules={[EffectCreative, Autoplay]}
            loop={false} // Disable looping
            onSlideChange={(swiper) => {
              if (swiper.isEnd) {
                swiper.autoplay.stop(); // Stop autoplay after the last slide
              }
            }}
          >
            <SwiperSlide>
              <img
                src={require('../assets/images/herobanner-img1.png')}
                alt=""
                className="sliderImage"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={require('../assets/images/herobanner-img2.png')}
                alt=""
                className="sliderImage"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="purchase-count pb-5" ref={section2Ref}>
        <Container>
          <Row className="justify-content-center w-100">
            <Col md={8} className="purchase-content">
              <h2 className="text-center fw-bold count-heading">
                Participate in the Earliest Purchase
              </h2>
              <p className="text-center launchtext">KICKSTARTER launches in</p>
              <div className="purcahse-count-item">
                <ul className="d-flex justify-content-center p-0">
                  <li className="mx-2 text-center">
                    <h4
                      style={
                        timeLeft?.days < 10
                          ? { color: 'red' }
                          : { color: 'black' }
                      }
                    >
                      {timeLeft?.days}
                    </h4>
                    <span className="fw-medium">Days</span>
                  </li>
                  <li className="mx-2 text-center">
                    <h4>{timeLeft?.hours}</h4>
                    <span className="fw-medium">Hours</span>
                  </li>
                  <li className="mx-2 text-center">
                    <h4>{timeLeft?.minutes}</h4>
                    <span className="fw-medium">Minutes</span>
                  </li>
                  <li className="mx-2 text-center">
                    <h4>{timeLeft?.seconds}</h4>
                    <span className="fw-medium">Second</span>
                  </li>
                </ul>
              </div>
              <div className="purchase-notify-btn text-center mt-3">
                <button
                  id="gtm-button-timer-notifyme"
                  className="btn text-bg-primary rounded-0 fw-semibold px-5 py-2"
                  type="button"
                  onClick={isLaunched ? goKickstarter : handleNotifyMe}
                >
                  {isLaunched ? 'GO KICKSTARTER' : 'Notify Me'}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="sliderSection position-relative py-5"
        ref={section3Ref}
      >
        <Container>
          {!isMobile && (
            <Swiper
              navigation={true}
              loop={true}
              spaceBetween={0}
              // slidesPerView={2.5}
              autoplay={{
                delay: 2500, // Delay between slides in milliseconds
                disableOnInteraction: false, // Continue autoplay after user interaction
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1.4,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
              }}
            >
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-1'}
                  src={require('../assets/images/slide-img-1.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-gif'}
                  src={require('../assets/images/slide-img-gif.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-2'}
                  src={require('../assets/images/slide-img-4.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-3'}
                  src={require('../assets/images/slide-img-3.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-4'}
                  src={require('../assets/images/slide-img-2.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-5'}
                  src={require('../assets/images/slide-img-5.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-6'}
                  src={require('../assets/images/slide-img-6.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-7'}
                  src={require('../assets/images/slide-img-7.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-8'}
                  src={require('../assets/images/slide-img-8.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-10'}
                  src={require('../assets/images/slide-img-10.webp')}
                ></img>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className={'gtm-img-slide gtm-img-slide-11'}
                  src={require('../assets/images/slide-img-11.webp')}
                ></img>
              </SwiperSlide>
            </Swiper>
          )}{' '}
        </Container>

        {isMobile && (
          <Swiper
            effect={'cards'}
            loop={true}
            grabCursor={true}
            modules={[EffectCards]}
            autoplay={{
              delay: 2500, // Delay between slides in milliseconds
              disableOnInteraction: true, // Continue autoplay after user interaction
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-1'}
                src={require('../assets/images/slide-img-1.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-gif'}
                src={require('../assets/images/slide-img-gif.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-2'}
                src={require('../assets/images/slide-img-4.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-3'}
                src={require('../assets/images/slide-img-3.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-4'}
                src={require('../assets/images/slide-img-2.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-5'}
                src={require('../assets/images/slide-img-5.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-6'}
                src={require('../assets/images/slide-img-6.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-7'}
                src={require('../assets/images/slide-img-7.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-8'}
                src={require('../assets/images/slide-img-8.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-10'}
                src={require('../assets/images/slide-img-10.webp')}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              <img
                className={'gtm-img-slide gtm-img-slide-11'}
                src={require('../assets/images/slide-img-11.webp')}
              ></img>
            </SwiperSlide>
          </Swiper>
        )}
      </section>
      <section className="video-section" ref={section4Ref}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <div>
                <h3 className="videosection-heading text-center mb-5">
                  <span>All-in-one:</span> <br />
                  Dive com + Underwater camera + Compass + Log book
                </h3>
                <iframe
                  src={`https://www.youtube.com/embed/${youtubeUrls.B}?autoplay=1&mute=1&rel=0&modestbranding=1&fs=1&iv_load_policy=3`}
                  frameBorder="0"
                  data-hj-allow-iframe="true"
                  allow="autoplay; encrypted-media; fullscreen;"
                  title="video"
                  width={'100%'}
                  className="videoDiv mb-4"
                />
                {/* <video width="100%" className='videoDiv mb-4' controls={false} autoPlay={true} muted={true} loop={true}>
                                    <source src={VideoContent} type="video/mp4" />
                                </video> */}
                <p className="text-left video-discription">
                  Purchasing a dive computer, compass, and camera separately for
                  diving can become increasingly expensive over time, not to
                  mention the bulkiness, which can be cumbersome during a dive.
                  At DIVEROID, we envisioned bringing the convenience of having
                  your smartphone always at hand by incorporating dive
                  monitoring technology. Our product includes a dive computer,
                  compass, and logbook—all in one.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {data.map((item, index) => (
        <ScrollImage
          key={item.id}
          text={item.text}
          BeforeImg={item.BeforeImg}
          AfterImg={item.AfterImg}
          index={index}
        />
      ))}
      <section>
        <History />
      </section>
      <section className="notify-section" ref={section5Ref}>
        <Container>
          <h4 className="section_heading text-center mb-5">
            {isLaunched
              ? 'Now launched on KICKSTARTER!!'
              : 'Sign up Now for a 1-Hour Early Access Alert'}
          </h4>
          <h1 className="section_heading_sub">
            <span className="sale-badge position-relative crossed-price text-center">
              $528
            </span>
            <PriceAnimation
              endValue={299}
              duration={1000}
              hasDiscount={true}
            ></PriceAnimation>{' '}
          </h1>
          <section
            className="purchase-count-bottom pb-1"
            ref={section2Ref}
            style={{
              paddingTop: '20px !important',
            }}
          >
            <div className="purcahse-count-item">
              <ul className="d-flex justify-content-center p-0">
                <li className="mx-2 text-center">
                  <h4
                    style={
                      timeLeft?.days < 10
                        ? { color: 'red' }
                        : { color: 'black' }
                    }
                  >
                    {timeLeft?.days}
                  </h4>
                  <span className="fw-medium">Days</span>
                </li>
                <li className="mx-2 text-center">
                  <h4>{timeLeft?.hours}</h4>
                  <span className="fw-medium">Hours</span>
                </li>
                <li className="mx-2 text-center">
                  <h4>{timeLeft?.minutes}</h4>
                  <span className="fw-medium">Minutes</span>
                </li>
                <li className="mx-2 text-center">
                  <h4>{timeLeft?.seconds}</h4>
                  <span className="fw-medium">Second</span>
                </li>
              </ul>
            </div>
          </section>
          {!isLaunched && (
            <div
              className="notify-card"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Row>
                {/* <Col lg={10}> */}
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    {/* <Col md={4}>
                    <FormGroup className="mb-3">
                        <FormLabel>Name</FormLabel>
                        <FormControl type="text" name="name" placeholder='Enter Full Name' onChange={formik.handleChange} value={formik.values.name} />
                        {formik.errors.name && formik.touched.name && (
                            <div style={{ color: "red" }}>{formik.errors.name}</div>
                        )}
                    </FormGroup>
                </Col> */}
                    <Row style={{ justifyContent: 'center' }}>
                      <Col md={8}>
                        <FormGroup className="mb-3">
                          <FormLabel>Email Address</FormLabel>
                          <FormControl
                            id="gtm-button-email-address"
                            type="email"
                            name="email"
                            placeholder="Enter your Email Address"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <div style={{ color: 'red' }}>
                              {formik.errors.email}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '1.5rem',
                        padding: '15px',
                        fontWeight: '800',
                      }}
                    >
                      OR
                    </Row>

                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <Col md={2} className="country-box">
                        <FormGroup className="mb-3">
                          <FormLabel>Country</FormLabel>
                          <Select
                            styles={{
                              control: (provided) =>
                                isMobile
                                  ? {
                                      ...provided,
                                      width: '3rem',
                                      height: '4rem',
                                      backgroundColor: '#F8F9FA',
                                      fontSize: '13px',
                                      fontWeight: '500',
                                    }
                                  : {
                                      ...provided,
                                      width: '6rem',
                                      height: '4rem',
                                      backgroundColor: '#F8F9FA',
                                    },
                              menu: (provided) => ({
                                ...provided,
                                width: '16rem',
                                fontWeight: '500',
                              }),
                              dropdownIndicator: (provided) =>
                                isMobile
                                  ? {
                                      ...provided,

                                      width: '0em',
                                      padding: '0px',
                                      display: 'none',
                                      border: '0px',
                                      backgroundColor: 'none',
                                    }
                                  : {
                                      ...provided,
                                    },
                              indicatorSeparator: (provided) =>
                                isMobile
                                  ? {
                                      backgroundColor: 'none',
                                    }
                                  : {
                                      ...provided,
                                    },
                            }}
                            maxMenuHeight={200}
                            name="countryCode"
                            options={countryCodeOptions}
                            value={selectedOption}
                            onChange={(selectedOption) =>
                              formik.setFieldValue(
                                'countryCode',
                                selectedOption ? selectedOption.value : ''
                              )
                            }
                            placeholder="Select Country Code"
                            formatOptionLabel={(option, { context }) => {
                              if (context === 'value') {
                                // 선택된 옵션일 때 표시할 형식
                                return (
                                  <div>
                                    {'+'}
                                    {option.value}
                                  </div>
                                );
                              } else {
                                // 나머지 옵션일 때 표시할 형식
                                return <div>{option.label}</div>;
                              }
                            }}
                          />
                          {formik.errors.countryCode &&
                            formik.touched.countryCode && (
                              <div style={{ color: 'red' }}>
                                {formik.errors.countryCode}
                              </div>
                            )}
                        </FormGroup>
                      </Col>

                      <Col md={6} className="phone-box">
                        <FormGroup className="mb-3">
                          <FormLabel>Phone Number</FormLabel>
                          <FormControl
                            type="text"
                            name="phone"
                            //   placeholder="Enter phone number without spaces or dashes"
                            placeholder="Enter your phone number"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                          />
                          {formik.values.phone.length > 0 &&
                            phoneValidate &&
                            !phoneValidate.isValid() && (
                              <div style={{ color: 'red' }}>
                                {'Not a valid phone number'}
                              </div>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: 'center' }}>
                      <Col lg={8}>
                        <div className="text-center">
                          <button
                            type="submit"
                            id="gtm-button-submit"
                            className="btn btn-primary w-100"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <ClipLoader size={20} color={'#fff'} />
                            ) : (
                              'Submit'
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </Form>
                {/* </Col> */}
              </Row>
            </div>
          )}
          {isLaunched && (
            <div className={'kickstarter-button-container-bottom'}>
              <div
                className={'kickstarter-button-bottom'}
                onClick={goKickstarter}
                style={{
                  border: '2px solid black',
                }}
              >
                GO KICKSTARTER
              </div>
            </div>
          )}
        </Container>
      </section>
      {!isLaunched && <StripeSection />}
      <footer
        className="footer"
        ref={section6Ref}
        style={{ marginTop: '100px' }}
      >
        <div className="position-relative overflow-hidden footerWave-div">
          <div className="footerWave waveFirst">
            <Wave
              fill="#198DFA"
              paused={false}
              style={{ display: 'flex' }}
              options={{
                height: 20,
                amplitude: 80,
                speed: 0.15,
                points: 7,
              }}
            />
          </div>
          <div className="footerWave waveSecond">
            <Wave
              fill="#198DFA"
              paused={false}
              style={{ display: 'flex' }}
              options={{
                height: 20,
                amplitude: 70,
                speed: 0.15,
                points: 4,
              }}
            />
          </div>
          <div className="footerWave waveThird">
            <Wave
              fill="#198DFA"
              paused={false}
              style={{ display: 'flex' }}
              options={{
                height: 20,
                amplitude: 50,
                speed: 0.15,
                points: 6,
              }}
            />
          </div>
        </div>
        <div className="footerbackground">
          <Container>
            <div className="text-center py-5">
              <img
                src={require('../assets/images/diveroid-img.png')}
                alt="logo"
                className="mb-5"
                width={200}
              />
              {/* <p className="text-white mb-0">ⓒ 2022 Artisan&Ocean</p> */}
            </div>
          </Container>
        </div>
      </footer>
    </>
  );
}
