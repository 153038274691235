import ReactGA from "react-ga4";
export const pageviewTrigger = (router) => {
  ReactGA.send({
    hitType: "pageview",
    page: router.pathname,
  });
};


export const eventTrigger = ({ action, category, label, value }) => {
  ReactGA.event({
    action: action,
    category: category,
    label: label,
    value: value,
  });
};


export const analyticEvent = (category, action) => {
  ReactGA.event({
      category,
      action,
  });   

}